/**
 * echart自定义主题
 * Author:  abner
 * Date: 2020-12-25
 * 注意：主题的优先级比setOption低
 */
let myTheme = {
    // 全图默认背景
    // backgroundColor: 'rgba(0,0,0,0)',
    // 全图文字
    "textStyle": {
        color: '#fff',
        fontFamily: 'Microsoft YaHei UI',
        fontSize: 14,
    },
    // 默认色板
    "color": ["#83bff6", "#23B7E5", "rgba(24, 141, 240,0.7)", "rgba(181, 155, 4, 1)", "rgba(83, 172, 173, 1)", 'rgba(250, 115, 50,0.7)', 'rgba(250, 44, 123,0.7)', 'rgba(255,56,224,0.7)', 'rgba(255,162,53,0.7)','rgba(14, 184, 116,0.7)'],
    "color1": ["#188df0", "#564AA3", "rgba(24, 141, 240,1)", "rgba(153, 207, 7, 1)", "rgba(35, 183,229,1)", 'rgba(250, 115, 50,1)', 'rgba(250, 44, 123,1)', 'rgba(255,56,224,1)', 'rgba(255,162,53,1)','rgba(14, 184, 116,1)'],
    //标题
    "title": {
        x: 'center',	//位置默认居中
        textStyle: {
            fontFamily: 'Microsoft YaHei UI',
            fontSize: 14,
            color: '#fff' // 主标题文字颜色
        },
        subtextStyle: {
            color: '#aaa'  // 副标题文字颜色
        }
    },
    // @图例
    "legend": {
        type: 'plain', //plain：普通图例 'scroll'：可滚动翻页的图例。当图例数量较多时可以使用。
        orient: 'horizontal', //垂直 vertical | 水平 horizontal。
        left: 'right',	//位置默认左
        // bottom: '0',
        textStyle: {
            color: '#fff',  // 图例文字颜色
            fontSize: '12px'
        }
    },
    // @工具栏  此配置无法起效，必须在option中配置
    // "toolbox": {
    //     show: true, // 默认显示
    //     feature: {
    //         dataZoom: {
    //             yAxisIndex: 'none'
    //         },
    //         dataView: { readOnly: false },
    //         magicType: { type: ['line', 'bar'] },
    //         restore: {},
    //         saveAsImage: {}
    //     }
    // },
    // @横轴(类目轴)
    "categoryAxis": {
        "axisLine": { // 坐标轴轴线
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": { // 刻度线
            "show": true,
            "lineStyle": {
                "color": "#fff"
            }
        },
        "axisLabel": { // 刻度值
            "show": true,
            // "rotate": 45,
            "textStyle": {
                "color": "#ddd", // 刻度标签文字的颜色，默认取 axisLine.lineStyle.color。
                "fontSize": 14,
            }
        },
        "splitLine": { // 坐标轴在 grid 区域中的分隔线。默认数值轴(横向)显示，类目轴(纵向)不显示。
            "show": false,
            "lineStyle": {
                "color": [
                    "#eee"
                ]
            }
        },
        "splitArea": { // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    // @纵轴(数值轴)
    "valueAxis": {
        "axisLine": { // 坐标轴轴线
            "show": true,
            "lineStyle": {
                "color": "#008acd"
            }
        },
        "axisTick": { // 刻度线
            "show": true,
            "lineStyle": {
                "color": "#fff"
            }
        },
        "axisLabel": { // 刻度值
            "show": true,
            "textStyle": {
                "color": "#fff",
                "fontSize": 14,
            },
        },
        "splitLine": { // 坐标轴在 grid 区域中的分隔线。默认数值轴(横向)显示，类目轴(纵向)不显示。
            "show": false,
            "lineStyle": { // 分隔线的样式设置。
                "color": [
                    "#ccc"
                ]
            }
        },
        "splitArea": { // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            "show": false,
            "areaStyle": { // 分隔区域的样式设置。
                "color": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        }
    },
    // @数据进度滑竿
    "dataZoom": {
        "show": true,
        "backgroundColor": "rgba(47,69,84,0)", // 组件的背景颜色。
        "dataBackgroundColor": "rgba(239,239,255,1)", // 数据阴影的样式。
        "fillerColor": "rgba(182,162,222,0.2)",
        "handleColor": "#008acd", // 手柄颜色。
        "handleSize": "100%",
        "textStyle": {
            "color": "#fff" //手柄文字颜色。
        }
    },

    // @点标记
    "markPoint": {
        "label": {
            "color": "#eeeeee"
        },
        "emphasis": {
            "label": {
                "color": "#eeeeee"
            }
        }
    }
}
export default myTheme;