<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

export default {
    name: 'app',
    components: {},
    mounted() {
        let parentWindow = window.parent;
        window.addEventListener('message', function (event) {
            if (event.source === parentWindow) {
                window.$glob.mainToken = event.data
                console.log('接收数据：', event.data);
            }
        });
    }
}
</script>

<style>
</style>
