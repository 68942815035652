import { validatenull } from '@/echart/util'
export const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}
export const createFile = () => {
  return {
    "title": "文件夹",
    "name": "文件夹",
    "index": uuid(),
    "children": []
  }
}


/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accSub(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); // last modify by deeka //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 给Number类型增加一个mul方法，调用起来更加方便。
Number.prototype.sub = function(arg) {
  return accMul(arg, this);
};

/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accAdd(arg1, arg2) {
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", "")) * cm;
    } else {
      arg1 = Number(arg1.toString().replace(".", "")) * cm;
      arg2 = Number(arg2.toString().replace(".", ""));
    }
  } else {
    arg1 = Number(arg1.toString().replace(".", ""));
    arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}

/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
  );
}

/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
export function accDiv(arg1, arg2) {
  var t1 = 0;
  var t2 = 0;
  var r1, r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {}
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {}
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

// 给Number类型增加一个div方法，调用起来更加方便。
Number.prototype.div = function(arg) {
  return accDiv(this, arg);
};

// 给Number类型增加一个mul方法，调用起来更加方便。
Number.prototype.mul = function(arg) {
  return accMul(arg, this);
};

// 给Number类型增加一个add方法，调用起来更加方便。
Number.prototype.add = function(arg) {
  return accAdd(arg, this);
};



export const compare = (propertyName) => {
  return function (object1, object2) {
    var value1 = object1[propertyName];
    var value2 = object2[propertyName];
    if (value2 < value1) {
      return -1;
    } else if (value2 > value1) {
      return 1;
    } else {
      return 0;
    }
  }
}

export const stringify = (json) => {
  let count = 0;
  let list = [];
  let str = JSON.stringify(json, function (key, val) {
    if (typeof val === 'function') {
      list.push(val + '');
      const result = '$code{' + count + '}$code'
      count = count + 1;
      return result
    }
    return val;
  }, 2);
  let startCode = '$code{';
  let endCode = '}$code';
  list.forEach((ele, index) => {
    str = str.replace(startCode + index + endCode, startCode + ele + endCode)
  })
  for (let i = 0; i < count; i++) {
    str = str.replace('"' + startCode, '').replace(endCode + '"', '')
  }
  return str
}

export const parse = (str) => {
  return JSON.parse(str, function (k, v) {
    if (v.indexOf && v.indexOf('function') > -1) {
      return eval("(function(){return " + v + " })()")
    }
    return v;
  });
}
export const addUrlParam = (url, param, value) => {
  if (!url) return
  function GetQueryString(name) {
    var num = url.indexOf("?")
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = url.substr(num + 1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }
  function replaceParamVal(paramName, value) {
    var re = eval('/(' + paramName + '=)([^&]*)/gi');
    return url.replace(re, paramName + '=' + value);
  }
  if (GetQueryString(param)) {
    return replaceParamVal(param, value)
  } else {
    if (url.includes('?')) {
      url = url + '&' + param + '=' + value
    } else {
      url = url + '?' + param + '=' + value
    }
    return url;
  }

}
export const funEval = (value) => {
  return new Function("return " + value + ";")();
}


export const getFunction = (fun, def) => {
  if (!validatenull(fun)) {
    try {
      return funEval(fun)
    } catch {
      return () => { }
    }
  } else if (def) return () => { }
}
export const getJson = (str) => {
  if (validatenull(str)) return {};
  else if (typeof str == "string") {
    try {
      return JSON.parse(str);
    } catch {
      return {}
    }
  } else {
    return str;
  }
}
export const checkUrl = (url) => {
  var reg = /http(s)?:\/\/([\w-.]+)+(:[0-9]+)?.*$/;
  if (!reg.test(url)) {
    return false;
  }
  else {
    return true
  }
}

/**
 * @author abner
 * @param {Object} obj 需要拼接的参数对象
 * @return {String}
 * */
export const obj2qs = (obj) => {
  if (!obj && !Object.keys(obj).length) {
    return "";
  } else {
    var arr = [];
    for (var key in obj) {
      arr.push(key + "=" + obj[key]);
    }
    return arr.join("&");
  }
}

/**
* @author abner
* @param {String} url url地址栏
* @return {Object}
*/
export const qs2obj = (url) => {
  var qs = url.split("?")[1];
  var arr = [];
  var res = {};
  if (!qs) {
    // return res;
  } else {
    arr = qs.split("&");
    for (var i = 0, len = arr.length; i < len; i++) {
      var key = arr[i].split("=")[0];
      var val = arr[i].split("=")[1];
      res[key] = decodeURIComponent(val);
    }
  }
  return res;
}

/**
 * 日期格式化
 * @param {*} time
 * @param {*} pattern
 * @returns
 * @author WangCong
 */
 export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      if (time.length === 14) {
        time = time.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, '$1/$2/$3 $4:$5:$6')
      } else {
        time = parseInt(time)
      }
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * 日期时间计算
 * @param {*} datetime 
 * @param {*} type 
 * @param {*} num 
 * @returns 
 * @author WangCong
 */
export function addOrReduceDate(datetime, type, num) {
	if (arguments.length === 0 || !datetime) {
		return null
	}
	var t_s = datetime.getTime();
	if (type == "D") {
		datetime.setTime(t_s + 1000 * 60 * 60 * 24 * num);
	}
	if (type == "H") {
		datetime.setTime(t_s + 1000 * 60 * 60 * num);
	}
	if (type == "M") {
		datetime.setTime(t_s + 1000 * 60 * num);
	}
	return datetime;
}
/**
 * 安全帽状态
 */
export function secureState(state){
	if(1){
		return '脱帽报警'
	}
	if(2){
		return '围栏报警(离开工作区域)'
	}
	if(3){
		return '温度报警'
	}
	if(4){
		return '跌落报警'
	}
	if(5){
		return '围栏报警(进入危险区域)'
	}
	if(6){
		return '静默报警'
	}
	if(7){
		return 'SOS紧急报警'
	}
	if(11){
		return '近电报警'
	}
	if(12){
		return '登高报警'
	}
	if(13){
		return '氧气报警'
	}
	if(14){
		return '甲烷报警'
	}
	if(15){
		return '氧气报警'
	}
	if(16){
		return '甲烷报警'
	}
	if(17){
		return '进入蓝牙信标报警'
	}
	if(18){
		return '离开蓝牙信标报警'
	}
	if(19){
		return '一氧化碳报警'
	}
	if(20){
		return '德隆手环温度报警'
	}
	if(21){
		return '德隆手环心率报警'
	}
	if(22){
		return '德隆血压报警'
	}
	if(23){
		return '硫化氢报警'
	}
	if(24){
		return '登高报警'
	}
	if(25){
		return '未佩戴手套'
	}
	if(26){
		return '单钩未挂接(安全带报警)'
	}
	if(27){
		return '双钩未挂接(安全带报警)'
	}
	if(28){
		return '体征报警'
	}
	if(29){
		return '低电量报警'
	}
	if(30){
		return '长时间离开围栏报警'
	}
	if(31){
		return '长时间进入围栏报警'
	}
	if(32){
		return '手环主动报警'
	}
}